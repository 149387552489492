.wrapper {
    margin-top: 20px;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 140%;
    color: var(--theme-default-text);
}

.wrapperTitle {
    font-weight: 700;
}

.wrapperInfo {
    font-weight: 300;
    margin-top: 5px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 30px;
}

.wallets {
    margin-top: 20px;
}

.walletsTitle {
    text-align: center;
}

.walletName {
    font-size: 15px;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    text-align: left;
}

.walletItem {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.walletWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
}

.walletWrap span {
    display: block;
    width: 85%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.walletWrap img {
    width: 24px;
    height: 24px;

    filter: var(--theme-default-filter)
}
