.tagline {
    position: relative;
    margin-bottom: 20px;
}

.tagline h1 {
    font-family: Roboto,sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: .02em;
    line-height: 160%;
    text-align: center;
    color: var(--theme-default-text);
}

.tagline button {
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 34px;
    height: 34px;

    border: none;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f;
    cursor: pointer;
}

.tagline button img {
    width: 24px;
}
