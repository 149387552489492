.wrapper {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.title {
    margin-bottom: 20px;
    text-align: right;
}

.subTitle {
    margin-bottom: 20px;

    font-size: 25px;
    color: #4B4E57;
}

.infoTitle {
    margin-bottom: 20px;

    font-weight: 700;
    line-height: 140%;
    text-align: right;

    color: var(--theme-default-text);
}

.targetHelps  button {
    /*width: 100%;*/
}
