.wrapper {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.title {
    margin-bottom: 20px;
    text-align: right;
}

.infoTitle {
    margin-bottom: 20px;

    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    text-align: right;
    color: var(--theme-default-text);
}

.text {
    text-align: center;
    font-size: 15px;
    font-weight: 300;
    color: var(--theme-default-text);
}

.helpsBlock {
    display: flex;
    flex-direction: column;
}
