.buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
}

.buttons button {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 22px;
    color: #0468E1;

    background-color: transparent;
    border: none;

    cursor: pointer;
}

.active {
    text-decoration: underline;
}

.langButton {
    display: flex;
    align-items: center;
    margin-left: 10px;

    color: var(--theme-default-text);
    background-color: transparent;
    border: none;

    cursor: pointer;
}

.langButton img {
    margin-right: 5px;
    width: 16px;
}
