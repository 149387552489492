.wrapper {
    margin-bottom: 50px;
    padding: 0 30px;

    display: flex;
    flex-direction: column;
    flex: 1;
}

.title {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: right;
    color: var(--theme-default-text);
}

.subTitle {
    font-weight: 400;
    font-size: 25px;
    color: #4B4E57;
}

.formWrapper button {
    width: 100%;
}

.inputFiles {
    position: relative;

    display: flex;
    align-items: center;
    margin-bottom: 40px;

    border: 1px solid black;
    border-radius: 10px;

    cursor: pointer;

    filter: var(--theme-default-filter);
}

.inputFiles input {
    position: absolute;
    opacity: 0;
    width: 0;
    z-index: -1;
}
