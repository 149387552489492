.container {
    flex: 1;
    padding: 0 30px;
}

@media (min-width: 600px) {
    .container {
        text-align: center;
    }
}

.title__block {
    margin: 0 auto 45px;
    max-width: 260px;
}

.title__block h1 {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--theme-default-text);
}

.title__block a {
    width: 100%;
    display: block;
    padding: 20px;
    margin-top: 35px;

    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--theme-default-buttonApp);

    background-image: var(--theme-default-buttonBg);

    border: 2px solid var(--theme-default-button-color);
    filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.25));
    border-radius: 60px;
}
