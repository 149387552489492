.button {
    position: fixed;
    bottom: 70px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 40px;

    border: none;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f;
    z-index: 3;
    cursor: pointer;
}

.left {
    left: 20px;
}

.right {
    right: 20px;
}

.left img {
    transform: rotate(180deg);
}

.button img {
    width: 30px;
}

@media (min-width: 600px) {
    .left {
        margin-right: auto;
        left: calc(50% - (var(--width-desktop) / 2) + 20px);
    }

    .right {
        margin-left: auto;
        right: calc(50% - (var(--width-desktop) / 2) + 20px);
    }
}
