:root {
    --theme-light-card-background: var(--color-white);
    --theme-dark-card-background: #252525;
    --theme-default-card-background: var(--theme-light-card-background);

    --theme-light-card-border-color: #D9D9D9;
    --theme-dark-card-border-color: #404040;
    --theme-default-card-border-color: var(--theme-light-card-border-color);

    --theme-light-card-wrapper-background: #F9F9F9;
    --theme-dark-card-wrapper-background: #303030;
    --theme-default-card-wrapper-background: var(--theme-light-card-wrapper-background);

    --theme-light-card-translate-color: #222222;
    --theme-dark-card-translate-color: var(--color-white);
    --theme-default-card-translate-color: var(--theme-light-card-translate-color);
}

.cards {
    display: flex;
    flex-direction: column;
}

.card {
    margin-bottom: 12px;
    padding: 10px;
    display: flex;
    flex-direction: column;

    background-color: var(--theme-default-card-background);
    border: 1px solid var(--theme-default-card-border-color);
    border-radius: 6px;
}

.cardDemand {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
}

.cardDemand img {
    margin-right: 5px;
}

.cardWrapper {
    padding: 10px;

    display: flex;
    flex-direction: column;

    background: var(--theme-default-card-wrapper-background);
    border-radius: 4px;
}

.cardDemandWrapper {
    display: flex;
    flex-direction: column;
}

.cardLink,
.title,
.text {
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: var(--theme-default-text);
}

.text {
    font-weight: 300;
    margin: 0;
}

.btnTranslate {
    text-align: right;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: var(--theme-default-card-translate-color);
    opacity: 0.4;

    background-color: transparent;
    border: none;

    cursor: pointer;
}

.btnTranslate:hover {
    text-decoration: underline;
}

.cardTranslate {
    transition: .2s opacity ease-in-out;
}

.cardTranslate.open {
    opacity: .5;
}

.cardOrigin {
    max-height: 0;
    overflow-y: hidden;
}

.cardOrigin.openOrigin {
    margin: 10px 0;
    max-height: max-content;
}

.cardOrigin {
    opacity: 0;
    transition: .2s opacity ease-in-out;
}

.cardOrigin.openOrigin {
    opacity: 1;
}

.cardPhoto {
    display: block;
    width: 80%;
    height: 80%;
    margin: 10px auto 0;
}

.cardLink:hover {
    text-decoration: none;
}

.cardButton {
    margin-top: 20px;
    padding: 5px 10px;
    align-self: flex-end;

    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;

    color: var(--theme-default-text);

    background-color: transparent;
    border: 1px solid var(--theme-default-card-border-color);
    border-radius: 4px;

    cursor: pointer;

    transition: filter .2s ease-in-out;
}
