.wrapper {
    padding: 15px;
    display: flex;
    align-items: flex-start;

    background: #FFFFFF;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
}

.wrapper div {
    display: flex;
    flex-direction: column;
    margin-left: 15px
}

.modalAvatarWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalAvatar {
    width: 230px;
    border-radius: 10px;
}

.modalRange {
    margin-top: 10px;
    width: 100%;

    cursor: pointer;
}

.modalFileLabel {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;

    border: 1px solid black;
    border-radius: 10px;

    cursor: pointer;
}

.modalFileLabel input {
    position: absolute;
    opacity: 0;
    width: 0;
    z-index: -1;
}

.avatar {
    width: 50px;
    border-radius: 10px;

    cursor: pointer;
}

.name {
    font-weight: 300;
    font-size: 18px;

    color: #1D2333;
}

.verify {
    display: flex;
    align-items: center;

    font-weight: 500;
    font-size: 11px;

    color: #1D2333;
}

.verify img {
    margin-right: 5px;
}

.edit {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;

    width: 34px;
    height: 34px;

    border: none;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f;
    cursor: pointer;
}

.edit img {
    width: 24px;
}

.h2 {
    font-size: 18px;
}

.span {
    font-size: 13px;
}
