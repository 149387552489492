.wrapper {
    margin-bottom: 50px;
    padding: 0 30px;

    display: flex;
    flex-direction: column;
    flex: 1;
}

.title {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: right;
}

.infoTitle {
    font-size: 15px;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    text-align: right;
}

.subTitle {
    margin-bottom: 5px;

    font-size: 25px;
    color: #4B4E57;
}


.wrapper p,
.wrapper a {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 20px;
}

.wrapper a {
    color: black;
}

.wrapper a:hover {
    text-decoration: none;
}

.info {
    font-size: 13px;
    text-align: center;
    color: darkred;
}
