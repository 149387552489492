.form {
    display: flex;
    flex-direction: column;
}

.formWrapper {
    display: flex;
    flex-direction: column;

    filter: var(--theme-default-filter)
}

.form input {
    margin-bottom: 10px;
    padding: 12px;

    font-weight: bold;
    font-size: 21px;
    text-align: center;

    background: #FAFAFA;

    border: 1px solid #F6F6F6;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.09);
    border-radius: 4px;

    outline: none;
}

.infoText {
    margin-bottom: 5px;

    font-size: 15px;
    font-weight: 300;
    font-family: Roboto, sans-serif;
}

.smallBtns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 20px;
}

.smallBtns button {
    width: 70px;
    height: 35px;

    font-size: 21px;
    font-weight: 400;
    text-align: center;
    color: black;
    background: #FFFFFF;
    border: 1px solid #F4F5F7;
    border-radius: 34px;
}

.smallBtns button:nth-child(3n + 2) {
    justify-self: center;
}

.smallBtns button:nth-child(3n + 3) {
    justify-self: end;
}

.rules {
    display: flex;
}

.rule {
    margin-left: 5px;

    font-weight: 300;
    font-size: 15px;
    text-decoration: underline;

    cursor: pointer;
}
