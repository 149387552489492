@font-face {
    font-family: "Manrope";
    src: url('../fonts/Manrope/Manrope-Light.woff2') format("woff2"),
    url("../fonts/Manrope/Manrope-Light.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    font-weight: 300;
}

@font-face {
    font-family: "Manrope";
    src: url('../fonts/Manrope/Manrope-Regular.woff2') format("woff2"),
    url("../fonts/Manrope/Manrope-Regular.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    font-weight: 400;
}

@font-face {
    font-family: "Manrope";
    src: url('../fonts/Manrope/Manrope-Medium.woff2') format("woff2"),
    url("../fonts/Manrope/Manrope-Medium.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    font-weight: 500;
}

@font-face {
    font-family: "Manrope";
    src: url('../fonts/Manrope/Manrope-Bold.woff2') format("woff2"),
    url("../fonts/Manrope/Manrope-Bold.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    font-weight: 700;
}

@font-face {
    font-family: "Roboto";
    src: url('../fonts/Roboto/Roboto-Light.woff2') format("woff2"),
    url("../fonts/Roboto/Roboto-Light.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    font-weight: 300;
}

@font-face {
    font-family: "Roboto";
    src: url('../fonts/Roboto/Roboto-Regular.woff2') format("woff2"),
    url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    font-weight: 400;
}

@font-face {
    font-family: "Roboto";
    src: url('../fonts/Roboto/Roboto-Medium.woff2') format("woff2"),
    url("../fonts/Roboto/Roboto-Medium.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    font-weight: 500;
}

@font-face {
    font-family: "Roboto";
    src: url('../fonts/Roboto/Roboto-Bold.woff2') format("woff2"),
    url("../fonts/Roboto/Roboto-Bold.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    font-weight: 700;
}

:root {
    --width-desktop: 600px;

    --color-white: #fff;
    --color-black: #101010;
    --color-darkgray: #101010;
    --color-yellow: #f7bc3a;

    --theme-light-body: #FCFCFC;
    --theme-dark-body: #303030;
    --theme-default-body: var(--theme-light-body);

    --theme-light-header: rgba(255, 255, 255, 0.6);
    --theme-dark-header: rgba(0, 0, 0, 0.6);
    --theme-default-header: var(--theme-light-header);

    --theme-light-button: #fff;
    --theme-dark-button: #303030;
    --theme-default-button: var(--theme-light-button);

    --theme-light-button-color: var(--color-black);
    --theme-dark-button-color: var(--color-yellow);
    --theme-default-button-color: var(--theme-light-button-color);

    --theme-light-buttonBg: url('../static/button-bg.png');
    --theme-dark-buttonBg: var(--color-darkgray);
    --theme-default-buttonBg: var(--theme-light-buttonBg);

    --theme-light-buttonApp: var(--color-white);
    --theme-dark-buttonApp: var(--color-yellow);
    --theme-default-buttonApp: var(--theme-light-buttonApp);

    --theme-light-text: #101010;
    --theme-dark-text: #fff;
    --theme-default-text: var(--theme-light-text);

    --theme-light-drawerBg: #b4b9c0;
    --theme-dark-drawerBg: #515356;
    --theme-default-drawerBg: var(--theme-light-drawerBg);

    --theme-light-appBg: #fff;
    --theme-dark-appBg: url('../static/gromada-bg.png');
    --theme-default-appBg: var(--theme-light-appBg);

    --theme-light-filter: invert(0);
    --theme-dark-filter: invert(1);
    --theme-default-filter: var(--theme-light-filter);

    --theme-light-modalBG: var(--color-white);
    --theme-dark-modalBG: #8f9091;
    --theme-default-modalBG: var(--theme-light-modalBG);
}

*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body,
#root {
    min-height: 100vh;
    height: 100%;
}

body {
    font-family: Manrope, sans-serif;
    font-size: 18px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-style: normal;

    background: linear-gradient(143deg, rgba(0, 87, 183, .7) 0%, rgba(255, 215, 0, .5) 100%);
}

.photoSlider.slick-slider {
    max-width: 320px;
    max-height: 320px;
    width: 100%;
    margin: 20px auto 50px;
}

.photoSlider.slick-slider .slick-list img {
    max-height: 320px;
    object-fit: contain;
}

.pageSlider.slick-slider {
    flex-grow: 1;
}

.title {
    font-family: 'Arial', sans-serif;
    font-weight: 300;
    font-size: 26px;
    line-height: normal;
}
