.btn {
    border: none;
    outline: none;

    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;

    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    padding: 20px;
    border-radius: 60px;

    transition: background .2s ease-in-out, box-shadow .2s ease-in-out;

    cursor: pointer;
}

.btn img {
    width: 24px;
    margin-left: 5px;
}

.btn--primary {
    color: var(--theme-default-button-color);
    background-color: var(--theme-default-button);

    border: 2px solid var(--theme-default-button-color);
}

.btn--primary:hover {
    box-shadow: 0 6px 16px rgba(60, 128, 220, 0.25);
}

.btn--primary:disabled {
    color: black;
    border-color: lightgray;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #D5D5D5;
    box-shadow: none;

    pointer-events: none;
}

.btn--secondary, .btn--cancel {
    color: #101010;

    border: 2px solid rgba(25, 33, 48, 0.4);

    filter: var(--theme-default-filter);
}

.btn--secondary:hover, .btn--cancel:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25);
}
