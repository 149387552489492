.label {
    display: flex;
    flex-direction: column;
    margin: 0 0 25px;

    font-size: 15px;
}

.label > span {
    margin-bottom: 5px;

    color: var(--theme-default-text);
}

.label textarea {
    height: 150px;


    padding: 15px;
    font-weight: 300;

    background: #FAFAFA;

    border: 1px solid #F6F6F6;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.09);
    border-radius: 4px;

    resize: vertical;

    outline: none;

    transition: border-color .2s ease-in-out;
}

.label.error textarea {
    border-color: #ff3939;
}

.label textarea::placeholder {
    font-weight: 300;
    color: #101010;

    opacity: 0.5;
}
