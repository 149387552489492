.app {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    min-height: 100vh;

    max-width: 600px;
    margin: 0 auto;

    background-color: var(--theme-default-body);

}

.image {
    width: 100%;
    margin-top: 50px;
}

.imageWrapper {
    background-image: var(--theme-default-appBg);
    background-repeat: no-repeat;
    background-size: cover;

    padding-bottom: 50px;
}

.backgroundImage {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    padding-top: 73%;

    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: -1px;
    background-position-y: 60px;
}

.container {
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
}

@media (min-width: 600px) {
    .app {
        overflow: hidden;
        height: 100%;
    }

    .container {
        overflow-y: scroll;
    }
}
