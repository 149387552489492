.header {
    position: fixed;
    top: 0;

    max-width: 600px;
    width: 100%;
    padding: 5px;

    background: var(--theme-default-header);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);

    z-index: 3;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header__logo {
    display: flex;
    align-items: center;

    text-decoration: none;
}

.header__logo img {
    margin-right: 5px;
}

.header__logo span {
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;

    color: #111111;
    opacity: 0.9;
}

.header__help {
    padding: 7px 13px;

    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    text-align: center;
    letter-spacing: 0.02em;
    white-space: nowrap;

    background-color: transparent;
    border: none;
    border-radius: 14px;
    text-decoration: none;
    box-shadow: 0 8px 12px -4px rgba(0, 0, 0, 0.15), 0 1px 6px rgba(0, 0, 0, 0.1);

    cursor: pointer;

    filter: var(--theme-default-filter);
}

.header__wrapper {
    display: flex;
    align-items: center;
}

.header__burger {
    margin-left: 10px;
    background: none;
    border: none;
    outline: none;

    cursor: pointer;

    filter: var(--theme-default-filter);
}

.header__burger img {
    width: 30px;
}

/* ******* */

.modalOfHelpUkraine {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
}

.modalOfHelpUkraine p {
    margin-bottom: 10px;
}

.modalOfHelpUkraine ul {
    margin-left: 15px;
    margin-bottom: 20px;
}

@media (min-width: 600px) {
    .header {
        padding: 15px 25px;
    }

    .header__logo img {
        margin-right: 10px;
    }

    .header__burger {
        margin-left: 20px;
    }
}
