.wrapper {
    margin-bottom: 50px;
    padding: 0 30px;

    display: flex;
    flex-direction: column;
    flex: 1;
}

.titleWrapper {
    filter: var(--theme-default-filter)
}

.title {
    margin-bottom: 20px;
    text-align: right;
}

.subTitle {
    margin-bottom: 5px;

    font-size: 25px;
    color: #4B4E57;
}

p {
    margin-bottom: 20px;
}

.info {
    font-size: 13px;
    text-align: center;
    color: darkred;
}
