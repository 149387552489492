.wrapper {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.title {
    margin-bottom: 20px;
    text-align: right;
    color: var(--theme-default-text) !important;
}

.subTitle {
    margin-bottom: 20px;

    font-size: 25px;
    color: #4B4E57;
}

.infoTitle {
    margin-bottom: 20px;

    font-weight: 300;
    font-size: 16px;
    line-height: 140%;

    color: var(--theme-default-text);
}
