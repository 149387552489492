.copy {
    display: flex;
    align-items: center;
    margin-top: 20px;

    cursor: pointer;
}

.copy img {
    margin-right: 10px;
    filter: var(--theme-default-filter);
}

.copy span {
    font-weight: 400;
    font-size: 20px;
    color: var(--theme-default-text);
}
