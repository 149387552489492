.label {
    display: flex;
    flex-direction: column;

    margin: 0 0 20px;
}

.labelText {
    margin-bottom: 5px;

    font-size: 15px;
    color: var(--theme-default-text);
}

.labelWrapper {
    position: relative;
}

.labelIcon {
    position: absolute;
    right: 10px;
    top: calc(50% - 15px);

    width: 30px;
    height: 30px;

    object-fit: cover;
    object-position: center;

    pointer-events: none;
}

.labelSelect {
    appearance: none;
    outline: 0;

    width: 100%;

    padding: 14px 40px 14px 10px;
    font-size: 16px;
    font-weight: 300;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    background: #FAFAFA;
    border: 1px solid #F6F6F6;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.09);
}
