.wrapper {
    margin-bottom: 50px;
    padding: 0 30px;

    display: flex;
    flex-direction: column;
    flex: 1;
}

.title {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: right;

    color: var(--theme-default-text)
}

.wrapper p {
    margin-bottom: 20px;

    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: var(--theme-default-text)
}

.successForm {
    margin-bottom: 10px;
}

.errorInput {
    color: red;
    display: block;
    font-size: 12px;
    margin-top: -17px;
}
