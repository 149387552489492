@keyframes showBackdrop {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes showModal {
    0% {
        opacity: 0;
        transform: translateY(-500px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.backdrop {
    position: fixed;
    top: 0; right: 0; left: 0; bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all .3s;
    animation: showBackdrop .3s ease-out;

    z-index: 4;
}

.modal {
    background: var(--theme-default-modalBG);
    width: 300px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.144);
    border-radius: 10px;
    transition: all .3s;
    animation: showModal .3s ease-out;

    padding: 20px;
}

.modalHeader {
    padding: 10px 20px;
    border-bottom: 1px solid rgb(219, 219, 219);

}

.modalHeader h3 {
    margin: 0;
    font-size: 20px;
}

.modalBody {
    max-height: 500px;
    overflow-y: scroll;

    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 140%;
    color: var(--theme-default-text);
}

.modalBody button {
    margin-top: 10px;
}

.modalBody p {
}

.modalFooter {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.modalFooter button:first-child {
    width: 100%;
}

.modalFooter button:nth-child(2) {
    margin-left: 10px;
}

.backdropHide {
    opacity: 0;
}

.backdropHide .modal {
    opacity: 0;
    transform: translateY(-500px);
}

