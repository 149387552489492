.switch {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 6px;

    margin-left: 20px;
}

.checkbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(196, 196, 196, .2);
    border-radius: 34px;

    transition: 0.4s;
}

.switch span:before {
    position: absolute;
    content:'';
    height: 20px;
    width: 20px;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;

    box-shadow: 0 8px 12px -4px rgba(0, 0, 0, 0.15), 0 1px 6px rgba(0, 0, 0, 0.1);
    background: white url('./img/sun.svg') no-repeat center;
    background-size: 16px;
    border-radius: 50%;

    transform: translateX(-5px);
    transition: 0.4s;
}

.checkbox:checked + span {
    background-color: #2196f3;
}

.checkbox:checked + span:before {
    transform: translateX(12px);
    background: white url('./img/night-moon.svg') no-repeat center;
    background-size: 16px;
}
