.checkbox {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    font-weight: 300;
    font-size: 15px;

    cursor: pointer;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.checkbox img {
    margin-right: 10px;
    width: 20px;
}
