.wrapper {
    margin-bottom: 50px;
    padding: 0 30px;

    display: flex;
    flex-direction: column;
    flex: 1;
}

.title {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: right;
    color: var(--theme-default-text);
}

.subTitle {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 15px;
}

.sliderWrapper p {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: var(--theme-default-text);
}

.sliderWrapper label {
    filter: var(--theme-default-filter);
}

@media (min-width: 600px) {
    .formWrapper {
        /*background-color: white;*/
        /*border-radius: 10px;*/
    }
}

.formWrapper button {
    width: 100%;
}

.errorMessage {
    display: block;
    margin-top: -17px;

    font-size: 12px;
    color: red;
}

.inputFiles {
    position: relative;

    display: flex;
    align-items: center;
    margin-bottom: 40px;

    border: 1px solid black;
    border-radius: 10px;

    cursor: pointer;

    filter: var(--theme-default-filter);
}

.inputFiles input {
    position: absolute;
    opacity: 0;
    width: 0;
    z-index: -1;
}

.modalOfHelpUkraine {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
}

.modalOfHelpUkraine p {
    margin-bottom: 10px;
}

.modalOfHelpUkraine ul {
    margin-left: 15px;
    margin-bottom: 20px;
}
