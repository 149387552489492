.footer {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.footer a {
    padding: 10px 40px;

    font-weight: 800;
    font-size: 15px;
    color: #424755;

    border: 1px solid rgba(67, 70, 77, 0.1);
    border-radius: 30px;

    filter: var(--theme-default-filter);
}
