.wrapper {
    margin-bottom: 50px;
    padding: 0 30px;
    flex: 1;
}

.title {
    margin-bottom: 20px;
    text-align: right;
}

.subTitle {
    margin-bottom: 20px;

    font-size: 25px;
    color: #4B4E57;
}

.infoTitle {
    margin-bottom: 20px;

    font-weight: 300;
    font-size: 16px;
    line-height: 140%;

    color: var(--theme-default-text);
}

.contributions {
    margin-top: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    background: rgba(237, 239, 242, 0.5);
    backdrop-filter: blur(16px);
    border-radius: 12px;
}

.contributions a {
    max-width: 260px;
    width: 100%;
    display: block;
    padding: 20px;
    margin-top: 35px;

    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--theme-default-buttonApp);

    background-image: var(--theme-default-buttonBg);

    border: 2px solid var(--theme-default-button-color);
    filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.25));
    border-radius: 60px;
}

.contribWrapper {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

}

.contribWrapper span:first-child {
    font-weight: 300;
    color: var(--theme-default-text);
}

.contribWrapper span:last-child {
    font-weight: 700;
    color: var(--theme-default-text);
}

.myRemand {
    margin-top: 20px;
}

.myRemand h2 {
    font-size: 18px;
    text-align: right;

    color: var(--theme-default-text);
}

.demand {
    margin-top: 15px;
    padding: 20px 15px;

    border: 1px solid #EDEDED;
    border-radius: 8px;

    cursor: pointer;
}

.demandWrapper {
    display: grid;
    grid-template-columns: 1fr 10px;
    margin-bottom: 15px;
}

.demandWrapper span {
    font-size: 15px;
    line-height: 120%;
    color: var(--theme-default-text);

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.demandWrapper img {
    align-self: center;
}

.demandButton {
    padding: 10px 20px;

    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;

    color: #101010;

    background-color: #fff;
    border: 1px solid rgba(25, 33, 48, 0.4);
    border-radius: 4px;

    cursor: pointer;
}

.demandInfo {
    font-size: 12px;
    color: darkred;
}
