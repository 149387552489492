.label {
    align-self: end;
    margin-bottom: 10px;

    cursor: pointer;
}

.switch {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 30px;
    margin: 0 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.input:checked + .slider {
    background-color: #001d8f;
}

.input:checked + .slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.text {
    display: inline-block;
    /*margin-left: 10px;*/
    font-weight: 300;
    font-size: 15px;
}
