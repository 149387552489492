.container {
    padding: 0 30px;
    flex: 1;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

.title {
    /*transform: translateY(-40px);*/
    margin-bottom: 20px;
    font-size: 18px;
    text-align: right;

    color: var(--theme-default-text);
}

.wrapper label[for="subscribe"]{
    /*transform: translateY(-25px);*/
}

.subTitle {
    margin-bottom: 20px;

    font-size: 25px;
    color: #4B4E57;
}

.infoTitle {
    margin-bottom: 20px;


    line-height: 140%;
    text-align: right;

    color: var(--theme-default-text);
}

.targetBlock {
    margin: 100px -30px 0;
    padding: 60px 30px;

    display: flex;
    flex-direction: column;

    background: rgba(229, 229, 229, 0.5);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
}
