.backdrop {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: block;
    z-index: 3;
    visibility: hidden;
    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: background-color, visibility;
}

.backdropShow {
     visibility: visible;
     background-color: rgba(0, 0, 0, .6);
 }

.drawer {
    transform: translate3d(101%, 0, 0);
    position: fixed;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    z-index: 4;
    transition: .2s;
    background-color: var(--theme-default-drawerBg);
}

@media (min-width: 600px) {
    .drawer {
        width: 300px;
    }
}

.drawerShow {
    transform: none;
    visibility: visible;
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);
}

.drawerHeader {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
}

.drawerHeader img {
    cursor: pointer;
}

.drawerContent {
    list-style: none;
    margin-left: 40px;
}

.drawerContent li {
    margin-bottom: 20px;
}

.drawerContent a {
    padding: 5px;
    display: block;
    font-size: 25px;
    line-height: 120%;
    text-decoration: none;

    color: var(--theme-default-text);
}


